<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <vuexy-logo />
          <b-img
            :src="appLogoImage"
            alt="logo"
          />
        </b-link>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            autocomplete="off"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email/Employee Code"
            >
              <validation-provider
                #default="{ errors }"
                name="Email/Employee Code"
                vid="email"
                rules="required"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Email/Employee Code"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <b-link :to="{name:'auth-forgot-password'}">
                  <small>Forgot Password?</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { $themeConfig } from '@themeConfig'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BInputGroup, BInputGroupAppend, BFormCheckbox, /* BRow, BCol, */ BLink, /* BCardText, BCardTitle, BImg, */ /* BAlert, */ VBTooltip,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getCompanyLoggedInUser } from '@/auth/utils'
import constants from '../../constants'
import { hideLoader, showLoader } from '@/utils/common'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    // BRow,
    // BCol,
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    // BCardText,
    // BCardTitle,
    // BImg,
    BForm,
    BButton,
    // BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      userData: {},
      menu: {},
      permission: [],
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    if (this.$route.query.t) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Token Expired',
          icon: 'Warning',
          variant: 'danger',
          text: '',
        },
      })
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(async success => {
        showLoader()
        if (success) {
          // document.getElementById('loading-bg').style.display = 'block'
          await useJwt.userslogin({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              if (response.data.code === constants.SUCCESS) {
                localStorage.setItem('accessToken', response.data.data.user_details.token.plainTextToken)
                localStorage.setItem('menu', JSON.stringify(response.data.data.menu))
                localStorage.setItem('userData', JSON.stringify(response.data.data.user_details))
                localStorage.setItem('permission', response.data.data.permission)
                this.userData = response.data.data.user_details
                this.menu = response.data.data.menu
                this.permission = response.data.data.permission

                // this.dataLocalize()
                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router.replace(getCompanyLoggedInUser(this.userData.user_type))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Welcome ${this.userData.firstname || this.userData.username}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'You have successfully logged in',
                      },
                    })
                  })
                hideLoader()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Incorrect Details',
                    icon: 'Warning',
                    variant: 'danger',
                    text: `${response.data.msg}. Please check details`,
                  },
                })
                hideLoader()
              }
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Something Went Wrong!',
                  icon: 'Warning',
                  variant: 'danger',
                  text: `${error}`,
                },
              })
              hideLoader()
              // this.$refs.loginForm.setErrors(error.data)
            })
          // document.getElementById('loading-bg').style.display = 'none'
        }
      })
    },
    // dataLocalize() {

    // },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
a.brand-logo img {
  height: 50px;
}
</style>
